<template>
  <div>
    <b-alert
      v-if="neZakljucenCas && (typeof this.$route.params.id === 'undefined')"
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        {{ $t('PostojiNezakljucenCas') }}
      </h4>
      <div class="alert-body">
        <span>{{ $t('DaBiSteMogliDaDodateNoviCasPotrrebnoJeDaSviPrethodniBuduZakljuceni') }}</span>
      </div>
    </b-alert>
    <b-alert
      v-if="isteklaLicencaInstruktora"
      variant="danger"
      show
    >
      <div
        class="alert-body"
        style="padding: 30px;"
      >
        <span>{{ $t('VasaLicencaInstruktoraJeIstekla') }}</span>
      </div>
    </b-alert>
    <b-alert
      v-if="isteklaVozacka"
      variant="danger"
      show
    >
      <div
        class="alert-body"
        style="padding: 30px;"
      >
        <span>{{ $t('VasaVozackaDozvolaJeIstekla') }}</span>
      </div>
    </b-alert>
    <b-alert
      v-if="isteklaLicnaKarta"
      variant="danger"
      show
    >
      <div
        class="alert-body"
        style="padding: 30px;"
      >
        <span>{{ $t('VasaLicnaKartaJeIstekla') }}</span>
      </div>
    </b-alert>
    <router-link
      v-if="neZakljucenCas && (typeof this.$route.params.id === 'undefined')"
      :to="{
        name: 'instruktori-casovi-prakticne-obuke'
      }"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
      >
        {{ $t('Casovi') }}
      </b-button>
    </router-link>
    <b-row
      v-if="(!neZakljucenCas && !isteklaLicencaInstruktora && !isteklaVozacka && !isteklaLicnaKarta && (typeof this.$route.params.id === 'undefined')) || (typeof this.$route.params.id !== 'undefined')"
      class="match-height"
    >
      <b-col
        lg="12"
        md="6"
      >
        <b-card
          no-body
          class="card-payment"
        >
          <b-card-header>
            <b-card-title>{{ $t(title) }}</b-card-title>
            <b-card-title class="text-primary">
              {{ tekuciDatum }}
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <div
              v-if="Object.entries(proveraUslovaOdrzavanjaCasa).length"
            >
              <b-alert
                v-for="(instruktorError, key) in proveraUslovaOdrzavanjaCasa.proveraInstruktora.errors"
                :key="key"
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  {{ instruktorError }}
                </h4>
              </b-alert>
            </div>
            <!-- form -->
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('DatumCasa')"
                    label-for="datum_casa"
                    class="mb-2"
                  >
                    <b-form-datepicker
                      id="datum_casa"
                      v-model="datum"
                      :placeholder="$t('DatumCasa')"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      :locale="this.$i18n.locale"
                      :hide-header="hideHeader"
                      today-button
                      reset-button
                      close-button
                      show-decade-nav
                      :label-today-button="$t('Danas')"
                      :label-reset-button="$t('Ponisti')"
                      :label-close-button="$t('Zatvori')"
                      :label-prev-year="$t('PrethodnaGodina')"
                      :label-prev-month="$t('PrethodniMesec')"
                      :label-next-year="$t('SledecaGodina')"
                      :label-next-month="$t('SledeciMesec')"
                      :label-current-month="$t('TekuciMesec')"
                      :label-prev-decade="$t('MinusDesetGodina')"
                      :label-next-decade="$t('PlusDesetGodina')"
                      label-help=""
                      no-flip
                      @input="proveraUslova"
                    />
                    <small
                      v-if="formErrors['datum']"
                      class="text-danger"
                    >
                      {{ $t(formErrors['datum'][0]) }}
                    </small>
                    <small>
                      <b-badge
                        v-if="dnevno_ogranicenje_instruktora.status === 'greska'"
                        variant="danger"
                      >
                        {{ dnevno_ogranicenje_instruktora.text }}
                      </b-badge>
                    </small>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('Kandidat')"
                    label-for="kandidat"
                    class="mb-2"
                  >
                    <v-select
                      v-model="kandidat"
                      label="text"
                      :options="kandidati"
                      :placeholder="$t('IzaberiteKandidata')"
                      @input="proveraUslova"
                    />
                    <small
                      v-if="formErrors.kandidat_id"
                      class="text-danger"
                    >
                      <b-badge
                        variant="danger"
                      >
                        {{ $t(formErrors.kandidat_id) }}
                      </b-badge>
                    </small>
                    <small
                      v-if="Object.entries(proveraUslovaOdrzavanjaCasa).length"
                      class="text-danger"
                    >
                      <b-badge
                        v-for="(kandidatError, key) in proveraUslovaOdrzavanjaCasa.kandidat.errors"
                        :key="key"
                        variant="danger"
                      >
                        {{ $t(kandidatError) }}
                      </b-badge>
                      <b-badge
                        v-for="(kandidatObavestenje, key) in proveraUslovaOdrzavanjaCasa.kandidat.obavestenja"
                        :key="key"
                        variant="warning"
                      >
                        {{ $t(kandidatObavestenje) }}
                      </b-badge>
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('Vozilo') + ' (' + voziloLabelPocetnoStanjePutometar + 'km)'"
                    label-for="vozilo"
                    class="mb-2"
                  >
                    <v-select
                      v-model="vozilo"
                      label="text"
                      :options="vozila"
                      :placeholder="$t('IzaberiteVozilo')"
                      @input="proveraUslova"
                    />
                    <small
                      v-if="formErrors.vozilo_id"
                      class="text-danger"
                    >
                      <b-badge
                        variant="danger"
                      >
                        {{ $t(formErrors.vozilo_id) }}
                      </b-badge>
                    </small>
                    <small
                      v-if="Object.entries(proveraUslovaOdrzavanjaCasa).length"
                      class="text-danger"
                    >
                      <b-badge
                        v-for="(voziloError, key) in proveraUslovaOdrzavanjaCasa.vozilo.errors"
                        :key="key"
                        variant="danger"
                      >
                        {{ $t(voziloError) }}
                      </b-badge>
                      <b-badge
                        v-for="(voziloObavestenje, key) in proveraUslovaOdrzavanjaCasa.vozilo.obavestenja"
                        :key="key"
                        variant="warning"
                      >
                        {{ $t(voziloObavestenje) }}
                      </b-badge>
                    </small>

                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="8">
                  <b-form-group
                    :label="$t('KodCasa')"
                    label-for="kod_casa"
                    class="mb-2"
                  >
                    <v-select
                      v-model="kod_casa"
                      label="text"
                      :options="kodoviPrakticneObuke"
                      :placeholder="$t('KodCasa')"
                      @input="proveraUslova"
                    />
                    <small
                      v-if="formErrors.kod_casa"
                      class="text-danger"
                    >
                      <b-badge
                        variant="danger"
                      >
                        {{ $t(formErrors.kod_casa) }}
                      </b-badge>
                    </small>
                    <small
                      v-if="Object.entries(proveraUslovaOdrzavanjaCasa).length"
                      class="text-danger"
                    >
                      <b-badge
                        v-for="(kodCasaError, key) in proveraUslovaOdrzavanjaCasa.proveraKodaCasa.errors"
                        :key="key"
                        variant="danger"
                      >
                        {{ $t(kodCasaError) }}
                      </b-badge>
                    </small>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group
                    :label="$t('VanNaselja')"
                    class="mb-2"
                  >
                    <b-form-checkbox
                      v-model="van_naselja"
                      switch
                      @input="proveraUslova"
                    >
                      {{ $t("VN") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group
                    :label="$t('Opterecenje')"
                    class="mb-2"
                  >
                    <b-form-checkbox
                      v-model="opterecenje"
                      switch
                    >
                      {{ $t("O") }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="10">
                  <b-form-group
                    :label="$t('VremePocetkaCasa')"
                    label-for="vreme_pocetka"
                    class="mb-2"
                  >
                    <flat-pickr
                      id="vreme_pocetka"
                      v-model="vreme_pocetka"
                      class="form-control"
                      :config="configTimePicker"
                      @on-close="proveraUslova()"
                    />
                    <small
                      v-if="Object.entries(proveraUslovaOdrzavanjaCasa).length"
                      class="text-danger"
                    >
                      <b-badge
                        v-for="(pauzaError, key) in proveraUslovaOdrzavanjaCasa.pauza.errors"
                        :key="key"
                        variant="danger"
                      >
                        {{ $t(pauzaError) }}
                      </b-badge>
                    </small>
                    <small
                      v-if="Object.entries(proveraUslovaOdrzavanjaCasa).length"
                      class="text-danger"
                    >
                      <b-badge
                        v-for="(vremeCasaError, key) in proveraUslovaOdrzavanjaCasa.vremeCasa.errors"
                        :key="key"
                        variant="danger"
                      >
                        {{ $t(vremeCasaError) }}
                      </b-badge>
                    </small>
                  </b-form-group>
                </b-col>
                <b-col sm="2">
                  <b-form-group
                    :label="$t('Dvocas')"
                    class="mb-2"
                  >
                    <b-form-checkbox
                      v-model="dvocas"
                      switch
                      @change="proveraUslova()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('VremeZavrsetkaCasa')"
                    label-for="vreme_zavrsetka"
                    class="mb-2"
                  >
                    <flat-pickr
                      id="vreme_zavrsetka"
                      v-model="vreme_zavrsetka"
                      class="form-control"
                      :config="configTimePicker"
                      @on-close="proveraUslova()"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('StanjeNaPutometruPocetakCasa')"
                    label-for="pocetno_stanje_na_putometru"
                  >
                    <b-form-input
                      id="pocetno_stanje_na_putometru"
                      v-model="pocetno_stanje_na_putometru"
                      :placeholder="$t('StanjeNaPutometruPocetakCasa')"
                      autocomplete="off"
                      value=""
                      type="number"
                      debounce="1000"
                      @update="proveraUslova"
                    />
                    <small
                      v-if="Object.entries(proveraUslovaOdrzavanjaCasa).length"
                      class="text-danger"
                    >
                      <b-badge
                        v-for="(stanjeNaPutometruError, key) in proveraUslovaOdrzavanjaCasa.proveraStanjaNaPutometru.errors"
                        :key="key"
                        variant="danger"
                      >
                        {{ $t(stanjeNaPutometruError) }}
                      </b-badge>
                    </small>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('StanjeNaPutometruKrajCasa')"
                    label-for="krajnje_stanje_na_putometru"
                  >
                    <b-form-input
                      id="krajnje_stanje_na_putometru"
                      v-model="krajnje_stanje_na_putometru"
                      :placeholder="$t('StanjeNaPutometruKrajCasa')"
                      autocomplete="off"
                      value=""
                      type="number"
                      debounce="1000"
                      @update="proveraUslova"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-if="status === '' || status !== 'zakljucen' || odobrena_promena"
              >
                <b-col
                  sm="12"
                >
                  <b-button-group
                    size="sm"
                  >
                    <b-button
                      :disabled="!aktivnoDugmeZakazi"
                      variant="success"
                      @click="snimiCas('zakazan')"
                    >
                      {{ $t('Zakazi') }}
                    </b-button>
                    <b-button
                      :disabled="!aktivnoDugmeSnimi"
                      variant="primary"
                      @click="snimiCas('snimljen')"
                    >
                      {{ $t('Snimi') }}
                    </b-button>
                    <b-button
                      :disabled="!aktivnoDugmeZakljuci"
                      variant="outline-primary"
                      @click="snimiCas('zakljucen')"
                    >
                      {{ $t('ZakljuciCas') }}
                    </b-button>
                    <b-button
                      variant="outline-primary"
                      @click="resetForme"
                    >
                      {{ $t('ResetujFormu') }}
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-form>
            <!--/ form -->
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BFormGroup, BForm, BFormDatepicker, BFormInput, BFormCheckbox, BButton, BButtonGroup, BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BForm,
    BFormDatepicker,
    BFormInput,
    BFormCheckbox,
    BButton,
    BButtonGroup,
    vSelect,
    flatPickr,
    BBadge,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tekuciDatum: '',
      kandidati: [{ value: '', text: this.$t('IzaberiteKandidata') }],
      vozila: [{ value: '', text: this.$t('IzaberiteVozilo') }],
      data: {},
      hideHeader: true,
      datum: '',
      kandidat: [{ value: '', text: this.$t('IzaberiteKandidata') }],
      vozilo: '',
      kod_casa: '',
      van_naselja: false,
      opterecenje: false,
      dvocas: false,
      vreme_pocetka: '',
      vreme_zavrsetka: '',
      pocetno_stanje_na_putometru: '',
      krajnje_stanje_na_putometru: '',
      configTimePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
        disableMobile: true,
        minuteIncrement: 1,
      },
      kodoviPrakticneObuke: [
        { value: 'P', text: 'P' },
        { value: 'SL', text: 'SL' },
        { value: 'SRJ', text: 'SRJ' },
        { value: 'K', text: 'K' },
        { value: 'KP', text: 'KP' },
      ],
      formErrors: [],
      izabraniKandidat: {},
      izabranoVozilo: {},

      provera_pauze: {
        status: 'OK',
        text: '',
      },
      stanjeMinimalnaPauzaOk: false,

      postojiCasNaDatum: false,
      postoji_cas_na_datum: false,
      provera_postoji_cas_na_datum: {
        status: 'OK',
        text: '',
      },
      kandidatOk: false,
      voziloOk: false,
      kodCasaOk: false,
      vremePocetkaCasaOk: false,
      stanjeNaPutometruOk: false,
      krajnjeStanjeNaPutometruOk: false,
      title: '',
      neZakljucenCas: false,
      status: '',
      odobrena_promena: false,
      isteklaLicencaInstruktora: false,
      isteklaVozacka: false,
      isteklaLicnaKarta: false,
      dnevno_ogranicenje_instruktora: {
        status: 'OK',
        text: '',
      },
      dnevnoOgranicenjeInstruktoraOk: false,
      proveraUslovaOdrzavanjaCasa: {},
      aktivnoDugmeSnimi: false,
      aktivnoDugmeZakljuci: false,
      aktivnoDugmeZakazi: false,
      voziloLabelPocetnoStanjePutometar: '',
      isteklaLicencaInstruktoraNaDatum: false,
      isteklaVozackaInstruktoraNaDatum: false,
      isteklaLicnaKartaInstruktoraNaDatum: false,
    }
  },
  watch: {
    $route() {
      this.resetForme()
    },
    dvocas(newValue) {
      if (newValue === true) {
        this.vreme_zavrsetka = moment(this.vreme_pocetka, 'H:m').add(90, 'minutes').format('H:m')
      } else {
        this.vreme_zavrsetka = moment(this.vreme_pocetka, 'H:m').add(45, 'minutes').format('H:m')
      }
    },
    vreme_pocetka(newValue) {
      if (this.dvocas === true) {
        this.vreme_zavrsetka = moment(newValue, 'H:m').add(90, 'minutes').format('H:m')
      } else {
        this.vreme_zavrsetka = moment(newValue, 'H:m').add(45, 'minutes').format('H:m')
      }
    },
  },
  mounted() {
    this.resetForme()
    this.vreme_pocetka = moment(new Date()).format('H:m')
    this.vreme_zavrsetka = moment(new Date()).add(45, 'minutes').format('H:m')
    this.danasnjiDatum()
    this.pokupiListu()
  },
  methods: {
    async proveraUslova() {
      this.formErrors = {}
      const casId = typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : ''
      await this.$http.post('/instruktori/provera-uslova-odrzavanja-casa',
        {
          kandidat: this.kandidat.value,
          datum: this.datum,
          casId,
          dvocas: this.dvocas,
          vozilo: this.vozilo.value,
          vremePocetka: `${this.datum} ${this.vreme_pocetka}`,
          vremeZavrsetka: `${this.datum} ${this.vreme_zavrsetka}`,
          pocetnoStanje: this.pocetno_stanje_na_putometru === '' ? 0 : this.pocetno_stanje_na_putometru,
          krajnjeStanje: this.krajnje_stanje_na_putometru === '' ? 0 : this.krajnje_stanje_na_putometru,
          kodCasa: this.kod_casa.value,
          vanNaselja: this.van_naselja,
        }).then(response => {
        const parsedResponse = JSON.parse(JSON.stringify(response.data))
        this.proveraUslovaOdrzavanjaCasa = parsedResponse.response
        this.aktivnoDugmeZakljuci = parsedResponse.provera
        this.aktivnoDugmeSnimi = parsedResponse.proveraSnimanje
        this.aktivnoDugmeZakazi = parsedResponse.proveraZakazivanje
        this.voziloLabelPocetnoStanjePutometar = parsedResponse.response.vozilo.pocetnoStanjePutometar ? parsedResponse.response.vozilo.pocetnoStanjePutometar : ''
      })
    },
    danasnjiDatum() {
      this.tekuciDatum = moment(new Date()).format('DD.MM.YYYY.')
      this.datum = moment(new Date()).format('YYYY-MM-DD')
    },
    pokupiListu() {
      const casId = typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : ''

      this.proveraDnevnogOgranicenjaInstruktora()
      this.$http.get(`/instruktori/unos-casa/${casId}`).then(response => {
        this.neZakljucenCas = response.data.neZakljucenCas
        this.isteklaLicencaInstruktora = response.data.isteklaLicencaInstruktora
        this.isteklaVozacka = response.data.isteklaVozacka
        this.isteklaLicnaKarta = response.data.isteklaLicencaInstruktora

        Object.entries(response.data.kandidati.kandidatiSaKojimaJeRadio).forEach(value => {
          this.kandidati.push({ value: value[1].id, text: `${value[1].ime} ${value[1].prezime} - ${value[1].idkandidata}` })
        })
        Object.entries(response.data.kandidati.ostaliKandidati).forEach(value => {
          this.kandidati.push({ value: value[1].id, text: `${value[1].ime} ${value[1].prezime} - ${value[1].idkandidata}` })
        })
        Object.entries(response.data.vozila).forEach(value => {
          this.vozila.push({ value: value[1].id, text: `${value[1].marka} ${value[1].tip} - ${value[1].regbroj}` })
        })
        if (typeof this.$route.params.id !== 'undefined') {
          this.title = this.$i18n.t('IzmeniCas')
          this.$http.get(`/instruktori/casovi-prakticne-obuke/${this.$route.params.id}/edit`).then(res => {
            this.status = res.data.status
            this.odobrena_promena = res.data.odobrena_promena
            this.datum = res.data.datum
            const [selektovaniKandidat] = this.kandidati.filter(e => e.value === res.data.kandidat_id)
            this.kandidat = selektovaniKandidat
            const [selektovanoVozilo] = this.vozila.filter(e => e.value === res.data.vozilo_id)
            this.vozilo = selektovanoVozilo
            const [selektovaniKodCasa] = this.kodoviPrakticneObuke.filter(e => e.value === res.data.kod_casa)
            this.kod_casa = selektovaniKodCasa
            this.van_naselja = res.data.van_naselja
            this.opterecenje = res.data.opterecenje
            this.vreme_pocetka = res.data.vreme_pocetka
            this.vreme_zavrsetka = res.data.vreme_zavrsetka
            this.dvocas = res.data.dvocas
            this.pocetno_stanje_na_putometru = res.data.pocetno_stanje_na_putometru
            this.krajnje_stanje_na_putometru = res.data.krajnje_stanje_na_putometru
            if (this.status !== 'zakljucen') {
              this.proveraUslova()
            }
          })
        } else {
          this.title = this.$i18n.t('NoviCas')
        }
      })
    },
    snimiCas(status = 'snimljen') {
      this.data = {
        status,
        datum: this.datum,
        kandidat_id: this.kandidat.value,
        vozilo_id: this.vozilo.value,
        kod_casa: this.kod_casa.value,
        van_naselja: this.van_naselja,
        opterecenje: this.opterecenje,
        dvocas: this.dvocas,
        vreme_pocetka: this.vreme_pocetka,
        vreme_zavrsetka: this.vreme_zavrsetka,
        pocetno_stanje_na_putometru: this.pocetno_stanje_na_putometru,
        krajnje_stanje_na_putometru: this.krajnje_stanje_na_putometru,
        odobrena_promena: 0,
        zahtevana_promena: 0,
      }
      if (typeof this.$route.params.id !== 'undefined') {
        this.$http.put(`/instruktori/casovi-prakticne-obuke/${this.$route.params.id}`, this.data)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('CasJeSnimljen'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            if (status === 'zakljucen') {
              this.$router.push({ name: 'instruktori-casovi-prakticne-obuke' })
            }
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      } else {
        this.$http.post('/instruktori/cas-prakticne-obuke', this.data)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('CasJeSnimljen'),
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'instruktori-casovi-prakticne-obuke' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.formErrors = error.response.data.errors
            }
          })
      }
    },
    resetForme() {
      this.formErrors = []
      this.datum = ''
      this.kandidat = { value: '', text: this.$t('IzaberiteKandidata') }
      this.vozilo = { value: '', text: this.$t('IzaberiteVozilo') }
      this.kod_casa = ''
      this.van_naselja = false
      this.opterecenje = false
      this.vreme_pocetka = ''
      this.vreme_zavrsetka = ''
      this.pocetno_stanje_na_putometru = ''
      this.krajnje_stanje_na_putometru = ''
      this.dvocas = false
      this.vreme_pocetka = moment(new Date()).format('H:m')
      this.vreme_zavrsetka = moment(new Date()).add(45, 'minutes').format('H:m')

      this.dnevnoOgranicenjeInstruktoraOk = true
      this.dnevno_ogranicenje_instruktora = {
        status: 'OK',
        text: '',
      }
      this.izabraniKandidat = {}
      this.proveraUslovaOdrzavanjaCasa = {}
      this.aktivnoDugmeSnimi = false
      this.aktivnoDugmeZakazi = false
      this.aktivnoDugmeZakljuci = false
    },

    proveraDnevnogOgranicenjaInstruktora() {
      this.$http.post('/instruktori/unos-casa/dnevno-ogranicenje-instruktora', { datum: this.datum })
        .then(response => {
          if (response.data.provera === true) {
            this.dnevno_ogranicenje_instruktora = {
              status: 'greska',
              text: this.$i18n.t('BrojCasovaZaDatumJeIspunjen'),
            }
            this.dnevnoOgranicenjeInstruktoraOk = false
          } else {
            this.dnevno_ogranicenje_instruktora = {
              status: 'OK',
              text: '',
            }
            this.dnevnoOgranicenjeInstruktoraOk = true
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('DnevnoOgranicenjeInstruktoraJeProvereno'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors
          }
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
